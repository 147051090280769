<template>
    <div class="main-box">
        <div class="pd-box">
            <div class="title" v-if="type === 'edit'">
                <p>序号：{{xuhao}}</p>
            </div>
            <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
                <div class="content">
                    <div class="con-item">
                        <a-form-item label="结算期">
                            <a-input v-model:value="jiesuanqi" placeholder="请输入结算期"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="险种类型">
                            <a-input v-model:value="xianzhongleixing" placeholder="请输入险种类型"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="缴费标志">
                            <a-input v-model:value="jiaofeibiaozhi" placeholder="请输入缴费标志"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="缴费总额">
                            <a-input v-model:value="jiaofeizonge" placeholder="请输入缴费总额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="单位缴费金额">
                            <a-input v-model:value="danweijiaofeijine" placeholder="请输入单位缴费金额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="个人缴费金额">
                            <a-input v-model:value="gerenjiaofeijine" placeholder="请输入个人缴费金额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="到账日期">
                            <a-input v-model:value="daozhangriqi" placeholder="请输入到账日期"></a-input>
                        </a-form-item>
                    </div>
                </div>
            </a-form>
        </div>
        <div class="con-box" style="text-align: center;">
            <a-button type="primary" style="margin-right: 20px;" @click="onSave">保存</a-button>
            <a-button type="primary" @click="$router.back()">取消</a-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'loginType',
            'isAuth',
            'orgId',
            'y'
        ])
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            id: '',
            xuhao: '',
            type: '',
            jiesuanqi: '',
            xianzhongleixing: '',
            jiaofeibiaozhi: '',
            jiaofeizonge: '',
            danweijiaofeijine: '',
            gerenjiaofeijine: '',
            daozhangriqi: '',
            ctype: ''
        }
    },
    methods: {
        // 获取医疗保险详情(工伤、生育、公积金)
        getOtherDetail () {
            this.$store.dispatch('getOtherDetail', { SHEBAODATA_OTHERS_ID: this.id, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    let datas = res.pd
                    this.jiesuanqi = datas.TERM
                    this.xianzhongleixing = datas.XIANZHONGTYPE
                    this.jiaofeibiaozhi = datas.JIAOFEISTATUS
                    this.jiaofeizonge = datas.JIAOFEITOTAL
                    this.danweijiaofeijine = datas.UNITJIAOFEI
                    this.gerenjiaofeijine = datas.PERSONALJIAOFEI
                    this.daozhangriqi = datas.RECEIVEDATE
                } else {
                    this.$message.error('获取详情失败，请稍后重试！')
                }
            })
        },
        onSave () {
            if (this.type === 'edit') {
                this.onEdit()
            } else if (this.type === 'add') {
                this.onAdd()
            }
        },
        // 添加医疗保险(工伤、生育、公积金)
        onAdd () {
            this.$store.dispatch('addOtherItem', {
                YEAR: this.y,
                ENTERPRISE_ID: this.orgId,
                TERM: this.jiesuanqi,
                XIANZHONGTYPE: this.xianzhongleixing,
                JIAOFEISTATUS: this.jiaofeibiaozhi,
                JIAOFEITOTAL: this.jiaofeizonge,
                UNITJIAOFEI: this.danweijiaofeijine,
                PERSONALJIAOFEI: this.gerenjiaofeijine,
                RECEIVEDATE: this.daozhangriqi,
                CTYPE: this.ctype,
                tm: new Date().getTime()
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('添加成功！')
                    setTimeout(() => {
                        this.$router.push({ path: '/ReportForms/OTHER', query: { ctype: this.getCtype() } })
                    }, 1500)
                } else {
                    this.$message.error('添加失败，请稍后重试！')
                }
            })
        },
        // 编辑医疗保险(工伤、生育、公积金)
        onEdit () {
            this.$store.dispatch('editOtherItem', {
                YEAR: this.y,
                ENTERPRISE_ID: this.orgId,
                TERM: this.jiesuanqi,
                XIANZHONGTYPE: this.xianzhongleixing,
                JIAOFEISTATUS: this.jiaofeibiaozhi,
                JIAOFEITOTAL: this.jiaofeizonge,
                UNITJIAOFEI: this.danweijiaofeijine,
                PERSONALJIAOFEI: this.gerenjiaofeijine,
                RECEIVEDATE: this.daozhangriqi,
                CTYPE: this.ctype,
                SHEBAODATA_OTHERS_ID: this.id,
                tm: new Date().getTime()
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('修改成功！')
                    setTimeout(() => {
                        this.$router.push({ path: '/ReportForms/OTHER', query: { ctype: this.getCtype() } })
                    }, 1500)
                } else {
                    this.$message.error('修改失败，请稍后重试！')
                }
            })
        },
        getCtype () {
            if (this.ctype === 'gongshang') {
                return 'GS'
            } else if (this.ctype === 'shengyu') {
                return 'SY'
            } else if (this.ctype === 'yiliao') {
                return 'YL'
            } else if (this.ctype === 'gongjijin') {
                return 'GJJ'
            } else {
                return ''
            }
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.id = this.$route.query.id
        this.xuhao = this.$route.query.num
        this.type = this.$route.query.type
        this.ctype = this.$route.query.ctype
        if (this.type === 'edit') {
            this.getOtherDetail()
        }
    }
}
</script>
<style lang="less" scoped>
.ant-form ::v-deep(label){
    font-size: 12px;
}
::v-deep(.ant-form-item-label){
    overflow: visible;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
    }

    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
            }
        }
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .con-item{
                width: 49%;
            }
        }
    }
}
</style>